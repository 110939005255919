
          @import "src/sass/global";
        
.top {
  background-color: #373737;
  display: flex;
  height: 100vh;

  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: #fff;
    font-size: 4em;
  }
}

.message {
  background-color: #f5deb3;
  padding: 20px 40px;
  border-radius: 4px;
}
