label .input-label-text {
  margin-left: 2px;
  margin-bottom: 5px;
  display: block;
}

label .error-message, .error-message {
  color: red;
  font-weight: normal;
}

input, textarea {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  background: #FFFFFF;

  @include elevated-subtle();

  border: 2px solid #D1D5DB;

  &::-webkit-input-placeholder {
    color: #6B7692;
  }

  &:focus, &:focus-visible {
    border: 2px solid #E0E7FF;
    outline: none;
  }

  &.cyan {
    border-color: $cyan;
    &:focus, &.focus {
      border: 2px solid #E0E7FF;
    }
  }

  &.invalid {
    border: 2px solid #EB4559;
    background-color: #FFBABE;
    padding-right: calc(1.5em + .75rem);
    background-image: url(../../images/field-error.svg);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);

    &:focus, &.focus {
      background-color: white;
      background-image: none;
    }
  }

  // without the icon for fields that have one already
  &.invalid__icon {
    background-image: none;
  }

  &:disabled {
    background: #F3F4F6;
    border: 2px solid #D1D5DB;
  }
}

textarea {
  height: 5em;
}

label.text {
  margin-bottom: 15px;
}

.field {
  .error-message {
    margin-top: 10px;
    clear: both;
  }
}

figure {
  position: relative;
  .error-message {
    background: white;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
