.select {
  button[role='combobox'] {
    @include elevated;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 15px;
    line-height: 1.1;
    width: 100%;
    background-color: white;
    border: none;

    span:nth-child(1) {
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:disabled {
      color: #6b7692ad;
      background-color: $input-disabled;
    }

    svg {
      margin-left: 7px;
    }
  }

  div[role='listbox'] {
    div[role='presentation'] {
      @include elevated;
      background: white;
    }
  }

  .item-indicator {
    svg {
      margin-left: 7px;
    }
  }

  div[role='option'] {
    display: flex;
    align-content: center;
    position: relative;
    user-select: none;
    margin: 0;
    padding: 0.4em;
    font: 100%/1.25 'Inter', sans-serif;
    color: $black;

    span:nth-child(1) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &[data-disabled] {
      color: $input-disabled;
      pointer-events: none;
    }

    &[data-state='checked'] {
      background-color: #e0e7ff;
    }
  }
}

.select-selected {
  button {
    color: #4a5266;
  }
}

select {
  appearance: none;
  background-image: url('../../images/chevron.svg');
  background-position: right 0.5rem center;
  background-size: 1em;
  background-repeat: no-repeat;
  background-color: white;
  // border should match _input but missing a variable for this one
  border: 2px solid #D1D5DB;
  border-radius: $border-radius-default;
  padding: $layout-narrow-padding 2rem $layout-narrow-padding $layout-default-padding;

  &:hover {
    border: 2px solid $color-blue-50;
  }

  &:focus-visible {
    // should match _input
    border: 2px solid #E0E7FF;
    outline: none;
  }

  &.narrow {
    min-width: 2rem;
  }

  &.invalid {
    // should match _input
    border: 2px solid #EB4559;
    background-color: #FFBABE;

    &:focus, &.focus {
      background-color: white;
      background-image: none;
    }
  }
}
