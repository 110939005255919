body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black-400;
  font-family: var(--body-font), 'Roboto', sans-serif;
  line-height: 1.65;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  margin: 0;
  position: relative;
  letter-spacing: -.3px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include typography-headings-base();

  b, strong {
    // ugh seo contractors
    font-weight: inherit;
  }
}

h1 {
  @include typography-size-xxl();
}

h2 {
  @include typography-size-xl();
}

h3 {
  @include typography-size-large();
}

h4 {
  @include typography-size-medium();
}

h5 {
  @include typography-size-small-medium();
}

h6 {
  @include typography-size-small();
}

.text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.caption,
label {
  font-family: var(--body-font), 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(17px);
  line-height: rem(21px);
  color: $black-400;
}

small {
  font-family: var(--body-font), 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: rem(14px);
  line-height: rem(17px);
}

a {
  /* indio darker */
  color: $anchor-color;
  text-decoration: none;

  &:visited {
    color: $anchor-color;
  }

  &:hover {
    text-decoration: underline;
  }
}

a.plain {
  color: inherit;
  &:visited,
  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

.gray-100 {
  color: $gray-100;
}

.gray-150 {
  color: $gray-150;
}

.gray-200 {
  color: $gray-200;
}

.gray-600 {
  color: $gray6;
}

.black {
  color: $gray-200;
}

.blue {
  color: $blue;
}

.blue-light {
  color: $blue-light;
}

.blue-light-1 {
  color: $panel-color;
}

.desire {
  color: $desire;
}

.cyan {
  color: $cyan;
}
