.checkbox {
  all: unset;
  width: 1em;
  height: 1em;
  border: 1px solid rgba(74, 82, 102, 0.4);
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  font: 100%/1.25 'Inter', sans-serif;
  background-color: white;
  flex-shrink: 0;

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    box-shadow: 0 0 0 2px black;
  }

  &[data-state='checked'] {
    border: none;
    background: $blue-light;
    height: 1em;
    width: 1em;
    display: inline-block;
  }

  &.cyan[data-state='checked'] {
    border: none;
    background: $cyan;
  }

  &:disabled {
    background: $input-disabled;
  }

  svg {
    color: white;
  }
}

label .checkbox:first-child,
label .toggle-switch:first-child,
{
  margin-left: 1px;
}

.toggle-switch {
  all: unset;
  width: 2em;
  height: 1em;
  background-color: $color-neutral-50;
  box-sizing: border-box;
  border-radius: 9999px;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  &[data-state='checked'] {
    background-color: $blue-light;
  }

  &:disabled {
    background-color: #f5f5f5;
    span {
      background-color: $color-neutral-50;
    }
    &:hover {
      opacity: 1;
    }
  }

  span {
    display: block;
    width: 0.8em;
    height: 0.75em;
    background-color: white;
    border-radius: 9999px;
    transition: transform 100ms;
    transform: translateX(2px);
    will-change: transform;
    &[data-state='checked'] {
      transform: translateX(1.0625em);
    }
  }

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    box-shadow: 0 0 0 2px black;
  }
}

.radio {
  .row {
    display: inline-flex;
    align-items: center;
  }

  &.cyan {
    span {
      &:after {
        background-color: $cyan;
      }
    }
  }

  button {
    all: unset;
    border: 1px solid #b7bac2;
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    margin: 0 10px;

    &[data-state='unchecked'] {
      background-color: white;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 58%;
        height: 60%;
        border-radius: 50%;
        background-color: $blue-light;
      }
    }
  }
}
