.elevated {
  @include elevated();
}

.elevated-subtle {
  @include elevated-subtle();
}

.elevated-more {
  box-shadow: 2px 3px 4px 6px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
}
