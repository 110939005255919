
          @import "src/sass/global";
        
@import 'src/sass/partials/normalize';
@import 'src/sass/partials/toggles';
@import 'src/sass/partials/elevated';
@import 'src/sass/partials/select';
@import 'src/sass/partials/input';
@import 'src/sass/partials/button';
@import 'src/sass/partials/avatar';
@import 'src/sass/partials/spinner';
@import 'src/sass/partials/tabs';
@import 'src/sass/partials/utilities';
@import 'src/sass/partials/typography';

:root {
  --color-fhf: #46d9e2;
  --color-yellow: #fff0d9;

  --color-gray-dark: #61687a;
  --color-gray-light: #f9f9f9;
  --color-gray-light1: #e8e8e8;

  --layout-base-margin: 1rem;
  --layout-container-max-width: 80em;

  --app-height: 100%;
}

/*
 * work around a dev server problem
 * https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing */
//iframe {
//  pointer-events: none;
//}

//html {
//  overflow-y: scroll;
//}

*,
*::before,
*::after {
  box-sizing: border-box;
}
