@mixin elevated {
  box-shadow: $box-shadow-elevated;
  border-radius: $border-radius-default;
}

@mixin elevated-highlighted {
  box-shadow: $box-shadow-elevated-blue;
  border-radius: $border-radius-default;
}

@mixin elevated-subtle {
  box-shadow: $box-shadow-elevated-subtle;
  border-radius: $border-radius-default;
}
