// original https://codeburst.io/how-to-create-a-simple-css-loading-spinner-make-it-accessible-e5c83c2e464c

$spacing-unit: 40px;
$quarter-spacing-unit: $spacing-unit / 4;
$half-spacing-unit: $spacing-unit / 2;
$double-spacing-unit: $spacing-unit * 2;

@mixin loading-spinner($activeColor: $blue, $selector: "&::before", $time: 1.5s) {
  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  animation-play-state: running;
  opacity: 1;
  position: relative;

  &.-paused {
    animation-play-state: paused;
    opacity: 0.2;
    transition: opacity linear 0.1s;
  }

  #{$selector} {
    animation: $time linear infinite spinner;
    animation-play-state: inherit;
    border: solid 3px $gray-150;
    border-bottom-color: #{$activeColor};
    border-radius: 50%;
    content: "";
    height: $spacing-unit;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: $spacing-unit;
    will-change: transform;
  }
}

.loading-spinner {
  @include loading-spinner;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: $double-spacing-unit;
  padding: $spacing-unit $spacing-unit 0;

  &.small {
    height: auto;

    &::before {
      height: 1rem;
      width: 1rem;
    }
  }

  &.right-align::before {
    right: 0;
    left: auto;
  }
}

body .btn.loading {
  @include loading-spinner($blue, '&::after');
  padding-right: 4em;

  &::after {
    height: 1em;
    width: 1em;
    left: unset;
    right: 10px;
  }
}
