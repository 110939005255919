.tab-triggers {
  border-bottom: 1px solid $color-gray-20;
  display: flex;
  gap: $layout-base-gap;
}

.connection-trigger-wrapper,
.tab-trigger,
.tab-trigger a {
  border-bottom: 4px solid transparent;
  color: $color-neutral-70;
  font-size: $typography-size-medium;
  padding-bottom: $layout-narrow-gap;

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:not([aria-selected='true']) &:hover {
    border-bottom: 4px solid $blue-light;
  }

  &[aria-selected='true'],
  [aria-selected='true'] {
    border-bottom: 4px solid $blue-light;
  }
}
