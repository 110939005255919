.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-danger {
  margin: $layout-default-padding 0;
  color: red;
}

.base-padding {
  padding: $layout-default-padding;
}

.hide-mobile {
  display: none;

  @include media('>=tablet') {
    display: initial;
  }
}

.reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

hr {
  border: none;
  @include hr;
}

.badge {
  align-items: center;
  background-color: $color-purple-50;
  border-radius: $border-radius-default;
  color: $color-neutral-inverted;
  display: inline-flex;
  font-size: $typography-size-medium;
  height: auto;
  justify-content: center;
  line-height: 1.2;
  margin-right: $layout-narrow-gap;
  padding: $layout-narrow-padding;
  width: max-content;
}

.badge-circular {
  align-items: center;
  background-color: $color-purple-50;
  border-radius: 50%;
  color: $color-neutral-inverted;
  display: inline-flex;
  font-size: $typography-size-medium;
  height: 2rem;
  justify-content: center;
  margin-right: $layout-narrow-gap;
  width: 2rem;
}

.info-bubble, .error-bubble {
  padding: $layout-default-padding;
  position: relative;
  border-radius: $border-radius-default;
  max-width: $layout-max-content-width;
}

.info-bubble {
  background-color: $color-blue-20;
}

.error-bubble {
  background-color: $color-message-box-error-background;
  color: $color-message-box-error-text;
}

.content-card {
  box-shadow:
    -2px -2px 5px #edecec,
    2px 2px 5px #edecec;
  border-radius: $border-radius-default;
  padding: $layout-base-gap;
}
