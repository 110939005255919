button:hover {
  cursor: pointer;
}

.btn {
  @include elevated();
  align-items: center;
  background-color: white;
  border: 1px solid $color-purple-50;
  border-radius: $border-radius-default;
  color: $black;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  gap: $layout-narrow-gap;
  height: fit-content;
  min-height: 3rem;
  justify-content: center;
  padding: $layout-narrow-padding $layout-default-padding;
  width: fit-content;
  white-space: nowrap;

  &:focus,
  &:hover {
    background: ghostwhite;
    // for links styled as buttons
    text-decoration: none;
  }

  &.primary {
    background: $blue;
    border-color: $blue;
    border-radius: 6px;
    color: white;
    font-weight: 500;

    &:focus,
    &:hover {
      background: white;
      color: $blue;
    }
  }

  &.primary-inverted {
    background: white;
    border-color: $blue;
    border-radius: 6px;
    color: $blue;
    font-weight: 500;

    &:focus,
    &:hover {
      background: $blue;
      color: white;
    }
  }

  &.secondary {
    background: white;
    border-color: white;
    color: $black;

    &:focus,
    &:hover {
      background: #4a5266;
      border-color: #4a5266;
      color: white;
    }
  }

  &.accept,
  &.decline {
    &::before {
      content: '';
      display: block;
      height: 1rem;
      width: 1rem;
    }
  }

  &.accept {
    background-color: $color-green-10;
    border-color: $color-green-50;
    color: $color-green-50;

    &::before {
      background-color: $color-green-50;
      mask-image: url('../../images/circle-filled-checkmark.svg');
    }
  }

  &.decline {
    background-color: $color-red-10;
    border-color: $desire;
    color: $desire;

    &::before {
      background-color: $desire;
      mask-image: url('../../images/circle-filled-close.svg');
    }
  }

  &.red,
  &.red-icon {
    background: $desire;
    border-color: $desire;
    color: white;

    &:focus,
    &:hover {
      background: white;
      color: $desire;
    }
  }

  &.red-icon {
    padding: 5px;
  }

  // like secondary but blue
  &.blue {
    color: $blue;
    background: white;

    &:focus,
    &:hover {
      background: $blue;
      color: white;
    }
  }

  // for icon buttons
  &.icon {
    max-width: max-content;
    // so it doesn't move
    border: 1px solid transparent;
    background: none;
    box-shadow: none;
    &:hover {
      border: 1px solid $black;
    }
    &.primary-inverted:hover {
      border-color: $blue;
    }
  }

  &.plain {
    border: none;
    background: none;
    box-shadow: none;
    &:disabled {
      background: none;
      &:hover {
        background: none;
      }
    }
  }

  &.extra-rounded {
    border-radius: 80px;
  }

  &.full-width {
    width: 100%;
  }

  &.wide {
    letter-spacing: 0.02em;
  }

  &.center {
    text-align: center;
  }

  &.lock {
    position: relative;
    svg {
      @include btn-lock;
    }
  }

  .show-desktop {
    display: none;

    @include media('>=desktop') {
      display: inline-block;
    }
  }

  &:disabled {
    background: #e1e1e1;
    color: #6b7692ad;
    cursor: not-allowed;
    &:hover,
    &:focus {
      background: #e1e1e1;
      color: #6b7692ad;
    }
  }

  // anchor styled as a button
  &.as-btn {
    &:hover {
      text-decoration: none;
    }
  }

  // a button styled as a link
  &.link {
    background: transparent;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: $anchor-color;
    max-height: unset;
    min-height: unset;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.button-row {
  margin-top: 2em;
  //margin-bottom: 1em;
  text-align: right;
  button:not(:nth-child(1)) {
    margin-left: 40px;
  }
  white-space: nowrap;

  &.no-margin {
    margin-top: 0;
  }

  &.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
